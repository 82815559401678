import 'frosted-ui/styles.css';
import ShopifyDemo from '../assets/videos/WAVES_SHOPIFY_DEMO.mov';
import '../App.css';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as Frosted from 'frosted-ui';
import { Advertiser } from './signup';


const SHOPIFY_SCOPES: string[] = [
    "write_draft_orders",
    "read_draft_orders",
    "write_products",
    "read_products",
    "write_discounts",
    "read_discounts",
    "write_price_rules",
    "read_price_rules"
];

export const OnboardingScreen = () => {
    const params = useParams();

    const [loading, setLoading] = useState(false);
    const [loadingAdvertiser, setLoadingAdvertiser] = useState(false);
    const [loadingCreateStripeConnect, setLoadingCreateStripeConnect] = useState(false);
    const [stripeAccount, setStripeAccount] = useState<any>(null);
    const [stripeAccountId, setStripeAccountId] = useState(null);
    const [loadingScopes, setLoadingScopes] = useState(false);
    const [scopes, setScopes] = useState<string[]>([]);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [advertiser, setAdvertiser] = useState<Advertiser | null>(null);


    const getAdvertiser = async () => {
        const result = await fetch(`https://ias-onboarding-api-2a16ef6cc75f.herokuapp.com/api/advertiser/${advertiser?._id}`, {
            method: "GET"
        });

        return await result.json();
    }

    const loadAdvertiser = async () => {
        setLoadingAdvertiser(true);
        const result = await fetch(`https://ias-onboarding-api-2a16ef6cc75f.herokuapp.com/api/advertiser/${params?.id}`, {
            method: "GET"
        });

        const data = await result.json();
        if (!data || !data.success) {
            window.location.href = "/"
            return;
        };

        if (
            data.advertiser.advertiserExternals.shopify?.shopify_access_token &&
            data.advertiser.advertiserExternals.shopify?.shopify_store
        ) {
            await handleGetAccessScopes(
                data.advertiser.advertiserExternals.shopify?.shopify_access_token,
                data.advertiser.advertiserExternals.shopify?.shopify_store
            );
        };

        setLoadingAdvertiser(false);
        return setAdvertiser(data.advertiser);
    };

    const handleGetStripeLink = async () => {
        const result = await fetch(`https://ias-onboarding-api-2a16ef6cc75f.herokuapp.com/api/advertiser/account_link/${advertiser?._id}/${advertiser?.advertiserPaymentProfile?.accountId}`, {
            method: "GET"
        });

        const data = await result.json();
        if (result.status == 200 && data && data.link) {
            return window.location.href = data.link;
        };

        return;
    };

    const handleCreateStripeConnect = async () => {
        setLoadingCreateStripeConnect(true);
        const result = await fetch('https://ias-onboarding-api-2a16ef6cc75f.herokuapp.com/api/advertiser/stripe_account', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                advertiserId: advertiser?._id
            })
        });

        setLoadingCreateStripeConnect(false);
        const data = await result.json()
        if (data && data.success && result.status === 200) {
            return setAdvertiser((prevState: any) => ({ ...prevState, advertiserPaymentProfile: { ...prevState.advertiserPaymentProfile, accountId: data?.account?.id } }))
        };
    };

    const formatCapability = (capability: string) => {
        if (capability === "bank_transfer_payments") return "Bank Transfers";
        if (capability === "card_payments") return "Card Payments";
        if (capability === "transfers") return "Transfers";
        return capability;
    };

    const formatRequirement = (requirement: string) => {
        if (requirement === "business_profile.mcc") return "• Merchant Category";
        if (requirement === "business_profile.url") return "• Business URL";
        if (requirement === "business_type") return "• Business Type";
        if (requirement === "external_account") return "• External Payouts Method";
        if (requirement === "tos_acceptance.date") return "• Terms of Service";
        if (requirement === "tos_acceptance.ip") return;
        if (requirement === "company.tax_id") return "• Company Tax ID";
        if (requirement.includes("email")) return "• Contact Email";
        if (requirement.includes("phone")) return "• Phone Number";
        if (requirement.includes("postal_code")) return "• Address [Postal Code]";
        if (requirement.includes("city")) return "• Address [City]";
        if (requirement.includes("state")) return "• Address [State]";
        if (requirement.includes("first_name")) return "• First Name";
        if (requirement.includes("last_name")) return "• Last Name";
        if (requirement.includes("ssn_last_4")) return "• SSN Last 4";


        return requirement;
    };

    const getStripeAccount = async () => {
        const result = await fetch(`https://ias-onboarding-api-2a16ef6cc75f.herokuapp.com/api/advertiser/account/${advertiser?.advertiserPaymentProfile?.accountId}`, {
            method: "GET"
        });

        const data = await result.json();
        if (data && data.success && result.status == 200) {
            return setStripeAccount(data?.account);
        };

        return;
    };

    const handleGetAccessScopes = async (accessKey?: string, storeName?: string) => {
        setError(false);
        setScopes([]);
        setLoadingScopes(true);
        const res = await fetch('https://ias-onboarding-api-2a16ef6cc75f.herokuapp.com/api/advertiser/scopes', {
            method: "POST",
            body: JSON.stringify({
                accessKey: accessKey ?? advertiser?.advertiserExternals?.shopify.shopify_access_token,
                storeName: storeName ?? advertiser?.advertiserExternals?.shopify.shopify_store
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const result = await res.json();
        if (result && result?.scopes?.length > 0) {
            setLoadingScopes(false);
            return setScopes(result?.scopes?.map((scope: any) => scope.handle));
        };

        setError(true); setErrorMsg("Error: Store Name or API Access key are invalid.")
        return setLoadingScopes(false);
    };

    const handleUpdateAdvertiser = async () => {
        setLoading(true);
        const response = await fetch(`https://2iijwteyx3.execute-api.us-east-1.amazonaws.com/prod/advertiser/update?id=${advertiser?._id}`, {
            method:"PUT",
            body: JSON.stringify({
                advertiserData:advertiser,
                advertiserId: advertiser?._id
            }),
        });
        
        
        if (response.status !== 200) return alert('An error occurred while updating your account. Please try again later.');
        const data = await response.json();
        if (!data || !data.success) return alert('An error occurred while updating your account. Please try again later.');

        try {
            const data = await getAdvertiser();
            if (data && data.success) {
                await handleGetAccessScopes(data.advertiser.advertiserExternals.shopify?.shopify_access_token, data.advertiser.advertiserExternals.shopify?.shopify_store);
                setAdvertiser(data.advertiser);
            };
        } catch (error) {};
        
        setLoading(false);
        return;
    };

    useEffect(() => {
        loadAdvertiser()
    }, []);

    useEffect(() => {
        if (advertiser?.advertiserPaymentProfile?.accountId) {
            getStripeAccount();
        };
    }, [advertiser]);

    if (loadingAdvertiser) return <div style={{ background: "#d7dbe0", height: '100%', width: '100%' }} />

    return (
        <div style={{ background: "#d7dbe0", height: '100%', width: '100%' }}>
            <div id='box-container' style={{ width: '90%', maxWidth: 700, marginLeft: 'auto', marginRight: 'auto', marginTop: '10%' }}>
                <div id='box' style={{ background: "#fff", width: '100%', padding: 11, borderRadius: 4, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)' }}>
                    <div style={{}}>
                        <h1 style={{ padding: 0, margin: 0, fontWeight: '600', fontSize: 18 }}>Shopify API Scope</h1>
                        <p style={{ padding: 0, margin: 0, fontWeight: '400', fontSize: 14, paddingTop: 5 }}>Enter your Shopify API key to verify the correct scopes are set up.</p>
                    </div>

                    <div style={{ marginTop: 15 }}>
                        <video controls autoPlay loop muted style={{ height: 'auto', width: '100%', borderRadius: 8, border: '1px solid #dee2e6' }}>
                            <source src={ShopifyDemo} type='video/mp4' />
                        </video>
                    </div>

                    <div id='inputs' style={{ marginTop: 15 }}>
                        <div id='store-name'>
                            <p style={{ padding: 0, margin: 0, fontWeight: '500', fontSize: 15 }}>Store Name</p>
                            <div style={{ flexDirection: 'row', display: 'flex', width: '100%', marginTop: 7 }}>
                                <input
                                    value={advertiser?.advertiserExternals?.shopify.shopify_store}
                                    onChange={(e) => setAdvertiser((prevState: any) => ({ ...prevState, advertiserExternals: { ...prevState.advertiserExternals, shopify: { ...prevState.advertiserExternals.shopify, shopify_store: e.target.value } } }))}
                                    placeholder='waves-test-store-1437'
                                    style={{
                                        height: 40,
                                        padding: 7,
                                        borderRadius: 4,
                                        border: '1px solid #dee2e6',
                                        fontSize: 15,
                                        color: "#000",
                                        fontWeight: '400'
                                    }}
                                />
                            </div>
                        </div>

                        <div id='api-key' style={{ marginTop: 15 }}>
                            <p style={{ padding: 0, margin: 0, fontWeight: '500', fontSize: 15 }}>API Access Key</p>
                            <div style={{ flexDirection: 'row', display: 'flex', width: '100%', marginTop: 7 }}>
                                <input
                                    value={advertiser?.advertiserExternals.shopify?.shopify_access_token}
                                    onChange={(e) => setAdvertiser((prevState: any) => ({ ...prevState, advertiserExternals: { ...prevState.advertiserExternals, shopify: { ...prevState.advertiserExternals.shopify, shopify_access_token: e.target.value } } }))}
                                    placeholder='shpat_0a911dce94b7.....'
                                    style={{
                                        height: 40,
                                        padding: 7,
                                        borderRadius: 4,
                                        border: '1px solid #dee2e6',
                                        fontSize: 15,
                                        color: "#000",
                                        fontWeight: '400'
                                    }}
                                />
                            </div>
                        </div>


                        {!SHOPIFY_SCOPES.every(scope => scopes.includes(scope)) && (
                            <div id='button' style={{ marginTop: 15 }}>
                                <button onClick={() => handleUpdateAdvertiser()} style={{ background: "#d8f3dc" }} className='custom-btn-hover'>
                                    <p style={{ color: "#1b4332", fontWeight: '600', fontSize: 15, letterSpacing: 0.25 }}>{loading? "•••":"Save Shopify Config"}</p>
                                </button>
                            </div>
                        )}
                    </div>


                    <div style={{ marginTop: 15 }}>
                        <p style={{ padding: 0, margin: 0, fontWeight: '500', fontSize: 15 }}>API Scopes</p>
                    </div>

                    <div className='custom-grid' style={{ marginTop: 15 }}>
                        {SHOPIFY_SCOPES.map((scope, key) => {
                            return (
                                <div key={key}>
                                    <Frosted.Code
                                        variant='soft'
                                        weight={"bold"}
                                        highContrast
                                        color={
                                            loadingScopes ? "gray" :
                                                scopes.includes(scope) ? "green" : "red"
                                        }
                                        style={{ padding: '5px 8px', fontWeight: '600' }}
                                    >
                                        {scope}
                                    </Frosted.Code>
                                </div>
                            )
                        })}
                    </div>

                    <div id='button' style={{ marginTop: 20 }}>
                        {SHOPIFY_SCOPES.every(scope => scopes.includes(scope)) ? (
                            <div style={{ padding: 10, borderRadius: 8, width: '100%', background: "#F1F4FFFF" }}>
                                <p style={{ textAlign: 'center', color: "#A2A2A1FF", fontWeight: '600' }}>All required scopes are enabled.</p>
                            </div>
                        ) : (
                            <button onClick={() => handleGetAccessScopes()} className='custom-btn-hover'>
                                <p style={{ color: "#fff", fontWeight: '700', fontSize: 15, letterSpacing: 0.25 }}>{loadingScopes ? "•••" : "Verify API Scopes"}</p>
                            </button>
                        )}
                    </div>
                </div>

                <div style={{ height: 50 }} />

                <div id='box' style={{ background: "#fff", width: '100%', padding: 11, borderRadius: 4, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)' }}>
                    <div style={{}}>
                        <h1 style={{ padding: 0, margin: 0, fontWeight: '600', fontSize: 18 }}>Stripe Connect</h1>
                        <p style={{ padding: 0, margin: 0, fontWeight: '400', fontSize: 14, paddingTop: 5 }}>Verify your Stripe account to collect payments made through in App Shopping.</p>
                    </div>


                    {!advertiser?.advertiserPaymentProfile?.accountId && (
                        <div id='temp-btn' style={{ marginTop: 15 }}>
                            <button disabled={loadingCreateStripeConnect} onClick={() => handleCreateStripeConnect()} className='custom-btn-hover'>
                                <p style={{ color: "#fff", fontWeight: '700', fontSize: 15, letterSpacing: 0.25 }}>{loadingCreateStripeConnect ? "•••" : "Create Stripe Connect"}</p>
                            </button>
                        </div>
                    )}

                    {stripeAccount && (
                        <div style={{ marginTop: 15 }}>
                            <p style={{ padding: 0, margin: 0, fontWeight: '500', fontSize: 15 }}>Capabilities</p>

                            <div className='custom-grid' style={{ marginTop: 15 }}>
                                {Object.keys(stripeAccount.capabilities).map((capability, key) => (
                                    <div key={key}>
                                        <Frosted.Code
                                            variant='soft'
                                            weight={"bold"}
                                            highContrast
                                            color={
                                                stripeAccount.capabilities[capability] === "active" ? "green" : "red"
                                            }
                                            style={{ padding: '5px 8px', fontWeight: '600' }}
                                        >
                                            {formatCapability(capability)}
                                        </Frosted.Code>
                                    </div>
                                ))}
                            </div>

                            {stripeAccount.requirements?.length > 0 ? (
                                <div style={{ marginTop: 20 }}>
                                    <button onClick={() => handleGetStripeLink()} className='custom-btn-hover'>
                                        <p style={{ color: "#fff", fontWeight: '700', fontSize: 15, letterSpacing: 0.25 }}>Complete Verification</p>
                                    </button>
                                </div>
                            ) : (
                                <div style={{ marginTop: 20 }}>
                                    <div style={{ padding: 10, borderRadius: 8, width: '100%', background: "#F1F4FFFF" }}>
                                        <p style={{ textAlign: 'center', color: "#A2A2A1FF", fontWeight: '600' }}>Your Stripe Connect account is verified.</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div style={{ marginTop: 20, height: 250, width: '100%' }} />
        </div>
    )
}