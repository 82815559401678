import 'frosted-ui/styles.css';
import * as Frosted from 'frosted-ui';
import { useSearchParams } from 'react-router-dom';
import { Zap, Plus } from 'lucide-react';
import WavesLogo from '../assets/WavesLogo.png';
import { useState } from 'react';
const WAVES_NOTION_URL = 'https://wavestechnologies.notion.site/wavestechnologies/Waves-Onboarding-Process-fbb2990edc1e44c0adfbbae331442869';

export interface Advertiser {
    _id?: string |null;
    advertiserName: string;
    advertiserPassword?: string | null;
    advertiserEmail: string;
    advertiserBio?: string | null;
    advertiserLogo?: string | null;
    advertiserWebsite?: string | null;
    advertiserExternals: {
        shopify: {
            shopify_access_token: string;
            shopify_store: string;
        }
    };
    advertiserSocialAccounts: {
        instagram?: string | null;
        twitter?: string | null;
        tiktok?: string | null;
    };
    advertiserPaymentProfile: {
        customerId: string;
        accountId: string;
        lastUpdated?: number | null;
    };
    tags: string[];
    brandInformation: {
        averageOrderValue?: number | 75;
    };
};


type STEP_TYPES = "BRAND" | "SHOPIFY" | "IAS";

export const SignupScreen = () => {
    const [uploading, setUploading] = useState(false);
    const [step, setStep] = useState<STEP_TYPES>("BRAND");
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const brandParam = searchParams.get('brand');
    const [advertiser, setAdvertiser] = useState<Advertiser>({
        advertiserName: brandParam || '',
        advertiserEmail: '',
        advertiserExternals: {
            shopify: {
                shopify_access_token: '',
                shopify_store: ''
            }
        },
        advertiserPaymentProfile: {
            customerId: '',
            accountId: ''
        },
        advertiserSocialAccounts: {},
        tags: [],
        brandInformation: {
            averageOrderValue: 75
        }
    });

    const handleFileUpload = async (file: any) => {
        setUploading(true);
        const formData = new FormData();
        formData.append('file', file);
        const base = 'https://waves-uploader-602dda6b651b.herokuapp.com/upload/advertiser/logo';
        const response = await fetch(base, {
            method: "PUT",
            body: formData,
        });

        if (response.status !== 200) return;
        
        const data = await response.json();
        const url = data?.data?.url;
        if (!url) return;

        setAdvertiser({ ...advertiser, advertiserLogo: url });
        setUploading(false);
        return;
    };


    const handleCreateFileUpload = async (e: any) => {
        e.preventDefault();
        const files = e.target.files;
        if (files.length > 1) return alert('You can only upload one file at a time.');

        const file = files[0];
        const result = await handleFileUpload(file);
        return result;
    };

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleCreateAdvertiser = async () => {
        if (advertiser.advertiserName.length === 0) return alert('Please enter a brand name.');
        if (advertiser.advertiserEmail.length === 0) return alert('Please enter a brand email.');
        if (!validateEmail(advertiser.advertiserEmail)) return alert('Please enter a valid email address.');
        setLoading(true);

        const response = await fetch('https://2iijwteyx3.execute-api.us-east-1.amazonaws.com/prod/advertiser/register', {
            method:"POST",
            body: JSON.stringify(advertiser),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        setLoading(false);
        const data = await response.json();
        if (!data || data.error) return alert('An error occurred while creating your account. Please try again later.');
        
        setAdvertiser(data.advertiser);
        return setStep("SHOPIFY");;
    };

    const handleUpdateAdvertiser = async () => {
        setLoading(true);
        const response = await fetch(`https://2iijwteyx3.execute-api.us-east-1.amazonaws.com/prod/advertiser/update?id=${advertiser?._id}`, {
            method:"PUT",
            body: JSON.stringify({
                advertiserData:advertiser,
                advertiserId: advertiser._id
            }),
        });
        
        if (response.status !== 200) return alert('An error occurred while updating your account. Please try again later.');
        const data = await response.json();
        if (!data || !data.success) return alert('An error occurred while updating your account. Please try again later.');
        setLoading(false);
        setAdvertiser(data.advertiser)
        return window.location.href = `/${advertiser._id}`;
    };

    return (
        <div style={{ background: "#d7dbe0", height: '100%', width: '100%' }}>
            <div id='box-container' style={{ width: '90%', maxWidth: 700, marginLeft: 'auto', marginRight: 'auto', marginTop: '10%' }}>
                <div id='box' style={{ background: "#fff", width: '100%', padding: 11, borderRadius: 4, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)' }}>
                    <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ padding: 5, background: '#212529', borderRadius: 5, marginRight: 10 }}>
                            <img src={WavesLogo} style={{ height: 25, width: 25 }} />
                        </div>
                        <Zap strokeWidth={2} />
                        <p style={{ fontSize: 24, padding: 0, margin: 0, fontWeight: 600, marginLeft: 10 }}>{brandParam || "Brand"} Onboarding</p>
                    </div>

                    <div style={{ marginTop: 15 }}>
                        <span style={{ fontSize: 16, fontWeight: 400, color: "#555" }}>Need help? View <a href={WAVES_NOTION_URL} target='_blank' style={{ textDecorationLine: "underline" }}>Waves</a> documentation.</span>
                    </div>


                    {step === "BRAND" && (
                        <div id='brand-container' style={{ marginTop: 15 }}>
                            <BrandComponent advertiser={advertiser} setAdvertiser={setAdvertiser} handleCreateFileUpload={handleCreateFileUpload} handleCreateAdvertiser={handleCreateAdvertiser} uploading={uploading} loading={loading} />
                        </div>
                    )}

                    {step === "SHOPIFY" && (
                        <div id='shopify-container' style={{ marginTop: 15 }}>
                            <ShopifyComponent advertiser={advertiser} setAdvertiser={setAdvertiser} updateBtn={handleUpdateAdvertiser} loading={loading} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};


export const ShopifyComponent = ({advertiser, setAdvertiser, updateBtn, loading}:{
    advertiser: Advertiser;
    setAdvertiser:any;
    updateBtn:any;
    loading:boolean;
}) => {
    return (
        <div id='container' style={{}}>
            <div id='shopifyConfig' style={{}}>
                <div id='store-name' style={{marginTop:15}}>
                    <p style={{ padding: 0, margin: 0, fontWeight: '500', fontSize: 15 }}>Store Name</p>
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', marginTop: 7 }}>
                        <input
                            placeholder='waves-test-store-1437'
                            value={advertiser.advertiserExternals.shopify.shopify_store}
                            onChange={(e) => setAdvertiser({ ...advertiser, advertiserExternals: { shopify: { ...advertiser.advertiserExternals.shopify, shopify_store: e.target.value } } })}
                            style={{
                                height: 40,
                                padding: 7,
                                borderRadius: 4,
                                border: '1px solid #dee2e6',
                                fontSize: 15,
                                color: "#000",
                                fontWeight: '400'
                            }}
                        />
                    </div>
                </div>

                <div id='shopify-api-key' style={{marginTop:15}}>
                    <p style={{ padding: 0, margin: 0, fontWeight: '500', fontSize: 15 }}>API Key</p>
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', marginTop: 7 }}>
                        <input
                            placeholder='shpss_1234567890...'
                            value={advertiser.advertiserExternals.shopify.shopify_access_token}
                            onChange={(e) => setAdvertiser({ ...advertiser, advertiserExternals: { shopify: { ...advertiser.advertiserExternals.shopify, shopify_access_token: e.target.value } } })}
                            style={{
                                height: 40,
                                padding: 7,
                                borderRadius: 4,
                                border: '1px solid #dee2e6',
                                fontSize: 15,
                                color: "#000",
                                fontWeight: '400'
                            }}
                        />
                    </div>
                </div>

                <div id='update-btn' style={{marginTop:15}}>
                    <button
                        onClick={() => updateBtn()}
                        className='custom-btn-hover'
                    >
                        <p style={{color:"#fff", fontWeight:'700', fontSize:15, letterSpacing:0.25}}>{loading ? "•••":"Save Shopify Config"}</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export const BrandComponent = ({ advertiser, setAdvertiser, handleCreateFileUpload, handleCreateAdvertiser, loading, uploading }: {
    advertiser: Advertiser;
    setAdvertiser: any;
    handleCreateFileUpload: any;
    handleCreateAdvertiser: any;
    loading: boolean;
    uploading: boolean;
}) => (
    <div id='container' style={{}}>
        <div id='brandName' style={{ marginTop: 0 }}>
            <p style={{ padding: 0, margin: 0, fontWeight: '500', fontSize: 15 }}>Brand</p>
            <div style={{ flexDirection: 'row', display: 'flex', width: '100%', marginTop: 7 }}>
                <input
                    placeholder='Brand Name'
                    value={advertiser.advertiserName}
                    onChange={(e) => setAdvertiser({ ...advertiser, advertiserName: e.target.value })}
                    style={{
                        height: 40,
                        padding: 7,
                        borderRadius: 4,
                        border: '1px solid #dee2e6',
                        fontSize: 15,
                        color: "#000",
                        fontWeight: '400'
                    }}
                />
            </div>
        </div>

        <div id='brandEmail' style={{ marginTop: 15 }}>
            <p style={{ padding: 0, margin: 0, fontWeight: '500', fontSize: 15 }}>Company Email</p>
            <div style={{ flexDirection: 'row', display: 'flex', width: '100%', marginTop: 7 }}>
                <input
                    placeholder='me@example.com'
                    value={advertiser.advertiserEmail}
                    onChange={(e) => setAdvertiser({ ...advertiser, advertiserEmail: e.target.value })}
                    style={{
                        height: 40,
                        padding: 7,
                        borderRadius: 4,
                        border: '1px solid #dee2e6',
                        fontSize: 15,
                        color: "#000",
                        fontWeight: '400'
                    }}
                />
            </div>
        </div>


        <div id='upload-logo' style={{ marginTop: 15 }}>
            <p style={{ padding: 0, margin: 0, fontWeight: '500', fontSize: 15 }}>Upload Logo</p>
            <div style={{ flexDirection: 'row', display: 'flex', width: '100%', marginTop: 7 }}>
                <input
                    type='file'
                    onChange={(e) => handleCreateFileUpload(e)}
                    style={{
                        height: 40,
                        padding: 7,
                        borderRadius: 4,
                        border: '1px solid #dee2e6',
                        fontSize: 15,
                        color: "#000",
                        fontWeight: '400'
                    }}
                />
            </div>
        </div>

        <div id='continue-btn' style={{ marginTop: 15 }}>
            <button
                onClick={() => handleCreateAdvertiser()}
                disabled={loading || uploading}
                className='custom-btn-hover'
            >
                <p style={{ color: "#fff", fontWeight: '700', fontSize: 15, letterSpacing: 0.25 }}>{loading ? "•••":"Continue"}</p>
            </button>
        </div>
    </div>
)