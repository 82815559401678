import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import WavesAnimated from './assets/WavesAnimated.gif';
import { Header } from './components/Header';
import { ShopifyBox } from './components/ShopifyBox';
import ShopifyDemo from './assets/videos/WAVES_SHOPIFY_DEMO.mov';
import {
  Routes,
  Route,
  useSearchParams,
  BrowserRouter,
  useParams
} from "react-router-dom"
import { StripeBox } from './components/StripeBox';
import { OnboardingScreen } from './screens/onboarding';
import { SignupScreen } from './screens/signup';


const Home = () => {
  const [stripeAccountId, setStripeAccountId] = useState(null);
  const [advertiser, setAdvertiser] = useState({
    _id: null,
    accessKey: "",
    storeName: "",
    stripe: {
      customerId: null,
      accountId: null,
    }
  });
  const params = useParams();

  const loadAdvertiser = async () => {
    const result = await fetch(`https://ias-onboarding-api-2a16ef6cc75f.herokuapp.com/api/advertiser/${params?.id}`, {
      method: "GET"
    });

    const data = await result.json();
    console.log({ data });

    if (!data || !data.success) return;

    return setAdvertiser({
      _id: data._id?.toString(),
      accessKey: data.advertiserExternals.shopify?.shopify_access_token,
      storeName: data.advertiserExternals.shopify?.shopify_store,
      stripe: {
        customerId: data.advertiserPaymentProfile.customerId,
        accountId: data.advertiserPaymentProfile?.accountId || null
      }
    })
  };

  useEffect(() => {
    loadAdvertiser()
  }, []);

  return (
    <div className="App">
      <Header />
      <ShopifyBox advertiser={advertiser} setAdvertiser={setAdvertiser} />
      <StripeBox advertiser={advertiser} setAdvertiser={setAdvertiser}/>
      <div style={{marginTop:20, height:250, width:'100%'}} />
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<div />} />
        <Route path='/:id' element={<OnboardingScreen />} />
        <Route path='/signup' element={<SignupScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
